/* eslint-disable jsx-a11y/media-has-caption */

import { useState, useRef, useEffect } from 'react'
import {
  Camera,
  CheckCircle2,
  Square,
  Upload,
  Video,
  Mic,
  X,
  Circle,
  Volume2,
  VolumeX,
} from 'lucide-react'
import { getStorage, ref, uploadBytes } from 'firebase/storage'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogTitle,
  DialogTrigger,
  DialogHeader,
} from '@/components/Dialog'
import { Button } from '@/components/Button'
import { getApp } from 'firebase/app'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '@/components/DropdownMenu'

type Recorder = { shutdown: () => void } & (
  | {
      state: 'uploading'
      previewUrl: string
      isMuted: boolean
      toggleMute: () => Promise<void>
    }
  | {
      state: 'shutdown'
      restart: () => Promise<void>
    }
  | {
      state: 'reviewing'
      previewUrl: string
      upload: () => Promise<void>
      clear: () => Promise<void>
      isMuted: boolean
      toggleMute: () => Promise<void>
    }
  | {
      state: 'recording-in-progress'
      stream: MediaStream
      stopRecording: () => Promise<void>
    }
  | { state: 'recording-starting'; stream: MediaStream; countdown: number }
  | {
      state: 'ready-to-record'
      stream: MediaStream
      videoDevices: MediaDeviceInfo[]
      audioDevices: MediaDeviceInfo[]
      selectedVideo: string
      selectedAudio: string
      setSelectedVideo: (deviceId: string) => void
      setSelectedAudio: (deviceId: string) => void
      startRecording: () => Promise<void>
    }
  | { state: 'initializing' }
  | { state: 'need-permissions'; requestPermission: () => Promise<void> }
)

function LivePreview({ stream }: { stream: MediaStream }) {
  const livePreviewRef = useRef<HTMLVideoElement | null>(null)

  useEffect(() => {
    const currentRef = livePreviewRef.current
    if (currentRef && currentRef.srcObject !== stream) {
      currentRef.srcObject = stream
      currentRef.play().catch((err) => {
        // eslint-disable-next-line no-console
        console.warn(err)
      })
    }
    return () => {
      if (currentRef) {
        currentRef.srcObject = null
      }
    }
  }, [stream])

  return (
    <video
      ref={livePreviewRef}
      autoPlay
      playsInline
      muted
      className="aspect-video w-full rounded-lg object-cover [transform:rotateY(180deg)]"
    />
  )
}

function useRecorder(answerId: string, customerId: string): Recorder {
  type InitializedMedia = {
    videoDevices: MediaDeviceInfo[]
    audioDevices: MediaDeviceInfo[]
    selectedVideo: string
    selectedAudio: string
    stream: MediaStream
  }
  type RecordingStarted = InitializedMedia & { mediaRecorder: MediaRecorder }
  type RecordingFinished = RecordingStarted & {
    previewUrl: string
    recordedBlob: Blob
    isMuted: boolean
  }
  type PrivateState =
    | { state: 'need-permissions' }
    | { state: 'initializing' }
    | { state: 'shutdown' }
    | (InitializedMedia & { state: 'ready-to-record' })
    | (InitializedMedia & { state: 'recording-starting'; countdown: number })
    | (RecordingStarted & { state: 'recording-in-progress' })
    | (RecordingFinished & { state: 'reviewing' })
    | (RecordingFinished & { state: 'uploading' })

  const chunksRef = useRef<BlobPart[]>([])
  const [state, setState] = useState<PrivateState>({
    state: 'need-permissions',
  })

  const initializeMedia = async (
    selectedVideo?: string,
    selectedAudio?: string
  ) => {
    const constraints = {
      video: selectedVideo ? { deviceId: { exact: selectedVideo } } : true,
      audio: selectedAudio ? { deviceId: { exact: selectedAudio } } : true,
    }

    setState({ state: 'initializing' })
    const stream = await navigator.mediaDevices.getUserMedia(constraints)

    const devices = await navigator.mediaDevices.enumerateDevices()
    const videoDevices = devices.filter(
      (device) => device.kind === 'videoinput'
    )
    const audioDevices = devices.filter(
      (device) => device.kind === 'audioinput'
    )

    setState({
      state: 'ready-to-record',
      stream,
      videoDevices,
      audioDevices,
      selectedVideo: selectedVideo || videoDevices[0].deviceId,
      selectedAudio: selectedAudio || audioDevices[0].deviceId,
    })
  }

  const shutdownMedia = () => {
    if (
      state.state === 'need-permissions' ||
      state.state === 'initializing' ||
      state.state === 'shutdown'
    )
      return
    state.stream.getTracks().forEach((track) => track.stop())
    setState({ state: 'shutdown' })
  }

  const toggleMute = async () => {
    if (state.state !== 'reviewing' && state.state !== 'uploading') return
    setState({ ...state, isMuted: !state.isMuted })
  }

  const setSelectedDevice = (deviceId: string, type: 'video' | 'audio') => {
    if (state.state !== 'ready-to-record') return
    initializeMedia(
      type === 'video' ? deviceId : state.selectedVideo,
      type === 'audio' ? deviceId : state.selectedAudio
    )
  }

  const startRecording = async () => {
    if (state.state !== 'ready-to-record') return

    setState({ ...state, state: 'recording-starting', countdown: 4 })

    const mediaRecorder = new MediaRecorder(state.stream)
    mediaRecorder.ondataavailable = (event) => {
      if (event.data.size > 0) {
        chunksRef.current.push(event.data)
      }
    }
    mediaRecorder.onstop = () => {
      const recordedBlob = new Blob(chunksRef.current, {
        type: 'video/webm',
      })
      const previewUrl = URL.createObjectURL(recordedBlob)
      setState({
        ...state,
        state: 'reviewing',
        mediaRecorder,
        previewUrl,
        recordedBlob,
        isMuted: false,
      })
      chunksRef.current = []
    }

    let count = 4
    const countdownInterval = setInterval(() => {
      count -= 1
      if (count > 0) {
        setState({
          ...state,
          state: 'recording-starting',
          countdown: count,
        })
      } else {
        clearInterval(countdownInterval)
        mediaRecorder.start()
        setState({
          ...state,
          state: 'recording-in-progress',
          mediaRecorder,
        })
      }
    }, 1000)
  }

  const stopRecording = async () => {
    if (state.state !== 'recording-in-progress') return
    state.mediaRecorder.stop()
  }

  const upload = async () => {
    if (state.state !== 'reviewing') return
    setState({ ...state, state: 'uploading' })
    const storage = getStorage(getApp(), 'saurai.firebasestorage.app')
    const storageRef = ref(
      storage,
      `customers/${customerId}/answers/${answerId}.webm`
    )
    await uploadBytes(storageRef, state.recordedBlob)
    setState({ ...state, state: 'reviewing' })
  }

  const clear = async () => {
    if (state.state !== 'reviewing') return
    setState({ ...state, state: 'ready-to-record' })
  }

  const result = {
    ...state,
    shutdown: shutdownMedia,
    restart: () => initializeMedia(),
    requestPermission: () => initializeMedia(),
    setSelectedVideo: (d: string) => setSelectedDevice(d, 'video'),
    setSelectedAudio: (d: string) => setSelectedDevice(d, 'audio'),
    toggleMute,
    startRecording,
    stopRecording,
    clear,
    upload,
  }
  return result
}

function SelectDevice({
  devices,
  selectedDevice,
  setSelectedDevice,
  type,
}: {
  devices: MediaDeviceInfo[]
  selectedDevice: string
  setSelectedDevice: (device: string) => void
  type: 'video' | 'audio'
}) {
  const Icon = type === 'video' ? Video : Mic

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          variant="secondary"
          className="flex h-12 flex-col items-center gap-1 "
        >
          <Icon className="h-6 w-6" />
          <span className="text-xs">
            {type === 'video' ? 'Video' : 'Audio'}
          </span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuRadioGroup
          value={selectedDevice}
          onValueChange={setSelectedDevice}
        >
          {devices.map((device) => (
            <DropdownMenuRadioItem
              key={device.deviceId}
              value={device.deviceId}
              className="cursor-pointer"
            >
              {device.label || `${type} ${device.deviceId.slice(0, 4)}`}
            </DropdownMenuRadioItem>
          ))}
        </DropdownMenuRadioGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

export type VideoQuestionProps = {
  answerId: string
  customerId: string
  videoUploaded: boolean
  markVideoUploaded: () => void
}

export function VideoQuestion({
  answerId,
  customerId,
  videoUploaded,
  markVideoUploaded,
}: VideoQuestionProps) {
  const recorder = useRecorder(answerId, customerId)
  const [isOpen, setIsOpen] = useState(false)

  const handleOpenChange = (open: boolean) => {
    if (open && recorder.state === 'shutdown') {
      recorder.restart()
    }
    if (!open) {
      recorder.shutdown()
    }
    setIsOpen(open)
  }

  return (
    <Dialog open={isOpen} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild>
        {videoUploaded ? (
          <Button variant="default" className="text-md items-center gap-2 ">
            <CheckCircle2
              size={20}
              className="mr-2 -translate-y-[0.6px] fill-green-500"
            />
            Video Recorded
          </Button>
        ) : (
          <Button variant="survey" className="text-md items-center gap-2 ">
            <Camera size={20} className="mr-2 -translate-y-[0.6px]" />
            Record a Video
          </Button>
        )}
      </DialogTrigger>
      <DialogContent size="lg" closeBtn>
        <DialogHeader>
          <DialogTitle>Record a Video</DialogTitle>
          <DialogDescription>
            Feel free to practice and re-record as many times as you&apos;d like
            before uploading your response.
          </DialogDescription>
        </DialogHeader>

        <div className="space-y-6">
          {recorder.state === 'need-permissions' ? (
            <div className="flex aspect-video items-center justify-center rounded-lg border border-gray-100 bg-gray-50/50 p-8">
              <div className="flex flex-col items-center space-y-4 text-center">
                <div className="rounded-full bg-blue-50 p-3">
                  <Camera size={24} className="text-blue-500" />
                </div>
                <div className="space-y-2">
                  <h3 className="text-base font-medium">
                    Camera Permission Required
                  </h3>
                  <p className="text-sm text-gray-500">
                    To record video, we&apos;ll need access to your camera &
                    microphone
                  </p>
                </div>
                <Button onClick={recorder.requestPermission} variant="default">
                  Allow Access
                </Button>
              </div>
            </div>
          ) : (
            <div className="space-y-4">
              <div className="relative rounded-lg border bg-gray-50/50">
                {recorder.state === 'recording-in-progress' ||
                recorder.state === 'recording-starting' ||
                recorder.state === 'ready-to-record' ? (
                  <LivePreview stream={recorder.stream} />
                ) : null}
                {recorder.state === 'initializing' ? (
                  <div className="flex aspect-video items-center justify-center bg-gray-50/80">
                    <div className="text-center text-gray-500">
                      <div className="animate-pulse">
                        <Camera
                          size={24}
                          className="mx-auto mb-2 text-gray-400"
                        />
                        <span className="text-sm">Launching camera...</span>
                      </div>
                    </div>
                  </div>
                ) : null}
                {recorder.state === 'reviewing' ||
                recorder.state === 'uploading' ? (
                  <div className="relative">
                    <video
                      src={recorder.previewUrl}
                      autoPlay
                      playsInline
                      loop
                      muted={recorder.isMuted}
                      controls={false}
                      className="aspect-video w-full rounded-lg object-cover"
                    />
                    <button
                      onClick={recorder.toggleMute}
                      className="group absolute inset-0 flex h-full 
                        w-full cursor-pointer items-center justify-center"
                    >
                      <div
                        className="h-16 w-16 rounded-full bg-black/50 p-4 
                        opacity-0 transition-opacity group-hover:opacity-70"
                      >
                        {recorder.isMuted ? (
                          <VolumeX className="h-full w-full text-white" />
                        ) : (
                          <Volume2 className="h-full w-full text-white" />
                        )}
                      </div>
                    </button>
                  </div>
                ) : null}

                {recorder.state === 'ready-to-record' ? (
                  <div className="absolute bottom-4 left-4 flex gap-2">
                    <SelectDevice
                      devices={recorder.videoDevices}
                      selectedDevice={recorder.selectedVideo}
                      setSelectedDevice={recorder.setSelectedVideo}
                      type="video"
                    />
                    <SelectDevice
                      devices={recorder.audioDevices}
                      selectedDevice={recorder.selectedAudio}
                      setSelectedDevice={recorder.setSelectedAudio}
                      type="audio"
                    />
                  </div>
                ) : null}

                {recorder.state === 'recording-starting' && (
                  <div
                    className="absolute inset-0 flex items-center justify-center 
                    bg-black/50 text-white"
                  >
                    <div className="text-center">
                      {recorder.countdown === 4 && (
                        <div className="animate-pulse text-4xl font-bold">
                          GET READY
                        </div>
                      )}
                      {recorder.countdown < 4 && (
                        <div className="text-6xl font-bold">
                          {recorder.countdown}
                        </div>
                      )}
                    </div>
                  </div>
                )}
              </div>

              <div className="flex justify-center gap-3">
                {recorder.state === 'ready-to-record' ||
                recorder.state === 'recording-starting' ? (
                  <Button
                    onClick={
                      recorder.state == 'ready-to-record'
                        ? recorder.startRecording
                        : undefined
                    }
                    variant="default"
                    className="flex items-center gap-2"
                    disabled={recorder.state === 'recording-starting'}
                  >
                    <Circle size={18} fill="red" />
                    {recorder.state === 'recording-starting'
                      ? 'Launching...'
                      : 'Record'}
                  </Button>
                ) : null}
                {recorder.state === 'recording-in-progress' ? (
                  <Button
                    onClick={recorder.stopRecording}
                    variant="danger"
                    className="flex items-center gap-2"
                  >
                    <Square size={18} />
                    Stop
                  </Button>
                ) : null}
                {recorder.state === 'reviewing' ||
                recorder.state === 'uploading' ? (
                  <>
                    <Button
                      onClick={
                        recorder.state === 'reviewing'
                          ? recorder.clear
                          : undefined
                      }
                      variant="outline"
                      disabled={recorder.state === 'uploading'}
                      className="flex items-center gap-2"
                    >
                      <X size={18} />
                      Clear
                    </Button>
                    <Button
                      onClick={async () => {
                        if (recorder.state === 'reviewing') {
                          await recorder.upload()
                        }
                        markVideoUploaded()
                        recorder.shutdown()
                        setIsOpen(false)
                      }}
                      variant={
                        recorder.state === 'uploading' ? 'loading' : 'default'
                      }
                      className="flex items-center gap-2"
                    >
                      <Upload size={18} />
                      Upload
                    </Button>
                  </>
                ) : null}
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  )
}

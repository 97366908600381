import { graphql, useFragment } from 'react-relay'
import { Card, CardContent } from '@/components/Card'
import { Button } from '@/components/Button'

import { Hero, HeroTitle } from '@/components/lms/Hero'
import { CheckCompletion_learner$key } from './__generated__/CheckCompletion_learner.graphql'
import { useState } from 'react'
import { useEndAssignment, useSetPractice } from './activityHooks'
import { activityName } from '@/common/utils'
import { useInteraction } from '@/components/Interaction'
const Fragment = graphql`
  fragment CheckCompletion_learner on Learner {
    id
    currentAssignment {
      id
      activity {
        name
      }
      ...ActivityFragment
    }
    practice {
      id
      nextPractice {
        id
      }
    }
  }
`

type CheckCompletionProps = {
  learner: CheckCompletion_learner$key
  onContinue: () => void
  onComplete: () => void
}

function CheckCompletion({
  learner: learnerFrag,
  onContinue,
  onComplete,
}: CheckCompletionProps) {
  const interact = useInteraction()
  const learner = useFragment(Fragment, learnerFrag)
  const [notFinished, setNotFinished] = useState(false)
  const [notContinue, setNotContinue] = useState(false)
  const [endAssignment] = useEndAssignment()
  const [setPractice] = useSetPractice(learner.currentAssignment?.id)
  const assignment = learner?.currentAssignment

  if (!assignment) return <div>No assignment</div> // TODO: redirect to a better place

  const handleNotContinue = () => {
    interact('checkCompletion_notContinue')
    setNotContinue(true)
    setTimeout(() => {
      if (learner.practice?.nextPractice?.id) {
        setPractice(learner.practice.nextPractice.id)
      } else {
        endAssignment(assignment.id, false)
      }
    }, 3000)
  }

  return (
    <>
      <Hero>
        <HeroTitle>Welcome back!</HeroTitle>
        <p className="mt-4 text-lg">
          You were working on{' '}
          <strong>{activityName(assignment.activity)}</strong>
        </p>
      </Hero>
      <Card className="space-y-6">
        <CardContent className="space-y-6">
          {!notFinished ? (
            <>
              <p>Did you finish this activity?</p>
              <div className="flex w-full justify-end gap-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    interact('checkCompletion_yes')
                    onComplete()
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    interact('checkCompletion_no')
                    setNotFinished(true)
                  }}
                >
                  No
                </Button>
              </div>
            </>
          ) : notContinue ? (
            <div className="space-y-3">
              <div className="h-2 overflow-hidden rounded-full bg-gray-100">
                <div className="h-full animate-[fill_2s_linear_forwards] bg-flintBlue" />
              </div>
              <p>Ok. Lets try something a little different.</p>
            </div>
          ) : (
            <>
              <p>Would you like to continue working on it?</p>
              <div className="flex w-full justify-end gap-2">
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    interact('checkCompletion_continue')
                    onContinue()
                  }}
                >
                  Yes
                </Button>
                <Button
                  variant="secondary"
                  size="sm"
                  onClick={handleNotContinue}
                >
                  No
                </Button>
              </div>
            </>
          )}
        </CardContent>
      </Card>
    </>
  )
}

export default CheckCompletion

/**
 * @generated SignedSource<<5c2206db8e2e3301aef81535634095ff>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type MyLearnerSurveyFragment$data = {
  readonly customer: {
    readonly id: string;
  };
  readonly id: string;
  readonly pendingSurveyFromManager: {
    readonly endDate: number | null;
    readonly id: string;
    readonly sections: ReadonlyArray<{
      readonly answers: ReadonlyArray<{
        readonly id: string;
        readonly " $fragmentSpreads": FragmentRefs<"QuestionCardFragment">;
      }>;
      readonly id: string;
    }>;
    readonly survey: {
      readonly id: string;
      readonly instructions: string;
      readonly title: string;
    };
    readonly " $fragmentSpreads": FragmentRefs<"completedAnswersFragment">;
  } | null;
  readonly surveyAwaitingResponseFromManager: {
    readonly id: string;
  } | null;
  readonly " $fragmentSpreads": FragmentRefs<"QuestionCardLearnerFragment" | "TemplateLearnerFragment">;
  readonly " $fragmentType": "MyLearnerSurveyFragment";
};
export type MyLearnerSurveyFragment$key = {
  readonly " $data"?: MyLearnerSurveyFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"MyLearnerSurveyFragment">;
};

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/)
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "MyLearnerSurveyFragment",
  "selections": [
    (v0/*: any*/),
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "TemplateLearnerFragment"
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "QuestionCardLearnerFragment"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Customer",
      "kind": "LinkedField",
      "name": "customer",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyResponse",
      "kind": "LinkedField",
      "name": "pendingSurveyFromManager",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "endDate",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Survey",
          "kind": "LinkedField",
          "name": "survey",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "instructions",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "title",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "SurveyResponseSection",
          "kind": "LinkedField",
          "name": "sections",
          "plural": true,
          "selections": [
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "SurveyAnswer",
              "kind": "LinkedField",
              "name": "answers",
              "plural": true,
              "selections": [
                (v0/*: any*/),
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "QuestionCardFragment"
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "completedAnswersFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "SurveyAssignment",
      "kind": "LinkedField",
      "name": "surveyAwaitingResponseFromManager",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    }
  ],
  "type": "Learner",
  "abstractKey": null
};
})();

(node as any).hash = "8a8316468fb6ceb711e8523307ffe1fa";

export default node;

import { Button } from '@/components/Button'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/Dialog'
import { CheckCircle2, Type } from 'lucide-react'
import { useState, useEffect } from 'react'

type TextQuestionProps = {
  answer: string | null
  randomize: boolean
  setAnswer: (answer: string) => void
}
export function TextQuestion({
  answer,
  randomize,
  setAnswer,
}: TextQuestionProps) {
  const [internalAnswer, setInternalAnswer] = useState(answer)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (randomize) {
      const text = Math.random().toString(36).substring(2)
      setInternalAnswer(text)
      setAnswer(text)
    }
  }, [answer, randomize, setAnswer])

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        {answer ? (
          <Button variant="default" className="text-md flex items-center gap-2">
            <CheckCircle2
              size={20}
              className="mr-2 -translate-y-[0.6px] fill-green-500"
            />
            Text Saved
          </Button>
        ) : (
          <Button variant="survey" className="text-md flex items-center gap-2">
            <Type size={20} className="mr-2 -translate-y-[0.6px]" />
            Type a Response
          </Button>
        )}
      </DialogTrigger>
      <DialogContent size="md" closeBtn>
        <DialogHeader>
          <DialogTitle>Type a Response</DialogTitle>
          <DialogDescription className="sr-only">
            Type your response here
          </DialogDescription>
        </DialogHeader>
        <textarea
          value={internalAnswer || ''}
          onChange={(e) => {
            setInternalAnswer(e.target.value)
          }}
          className="min-h-[6rem] w-full rounded-lg bg-white px-5 py-4 shadow-sm outline-flintOrange ring-2 ring-slate-900/5"
        />
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => {
              setIsOpen(false)
              setInternalAnswer(answer)
            }}
          >
            Cancel
          </Button>
          <Button
            variant="default"
            disabled={!internalAnswer}
            onClick={() => {
              if (internalAnswer) {
                setAnswer(internalAnswer)
                setIsOpen(false)
              }
            }}
          >
            Submit
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

import { clsx, type ClassValue } from 'clsx'
import { twMerge } from 'tailwind-merge'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function visualRound(digits: number, x: number) {
  return Math.round(x * 10 ** digits) / 10 ** digits
}

export function nanIfNull(x: number | null) {
  return x == null ? NaN : x
}

export function timeForInput(t: number) {
  return new Date(t - new Date().getTimezoneOffset() * 60000)
    .toISOString()
    .slice(0, -5)
}

// TODO: Eliminate once the information in these parentheticals has been moved
// out of the title into a separate "role"/"tenure" field.
export function activityName(activity: { name: string }): string {
  return activity.name.replace(/ *\((C1|C2|C3|L1|L2|L3)\)/g, '')
}

export const truncateString = (string: string, maxLength: number) => {
  return string.length > maxLength
    ? `${string.substring(0, maxLength)}...`
    : string
}

export const interpolateString = (
  str: string,
  vars: Record<string, string>
) => {
  return str.replace(/#\{(\w+)\}/g, (match, key) => vars[key] || match)
}

export const handlePriorityChange = (
  originalPriorities: { id: string; priority: number }[],
  id: string,
  swapOffset: number
) => {
  const priorities = originalPriorities.map((x) => ({
    id: x.id,
    priority: x.priority,
  }))
  const index = priorities.findIndex((node) => node.id === id)
  const target = index + swapOffset
  if (!(index === -1 || target < 0 || target >= priorities.length)) {
    ;[priorities[index].priority, priorities[target].priority] = [
      priorities[target].priority,
      priorities[index].priority,
    ]
  }
  return priorities.map((x) => ({
    _0: x.id,
    _1: x.priority,
  }))
}

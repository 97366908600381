import { type SyntheticEvent, useContext, useRef, useState } from 'react'

import { Button } from '@/components/Button'
import { Card, CardContent, CardTitle } from '@/components/Card'
import Markdown from '@/components/Markdown'
import { Navigate, useNavigate } from '@tanstack/react-router'

import {
  graphql,
  useFragment,
  useLazyLoadQuery,
  useMutation,
} from 'react-relay'
import { OrientationQuery } from './__generated__/OrientationQuery.graphql'
import { InteractionContext } from '@/components/Interaction'
import AppContainer from '@/components/lms/AppContainer/AppContainer'
import { Orientation_learner$key } from './__generated__/Orientation_learner.graphql'

const Fragment = graphql`
  fragment Orientation_learner on Learner {
    localizedLogoUrl
    seenWelcome
    fullName
    locale
    assignments {
      totalCount
    }
    customer {
      name
      aboutPageTitle
      aboutPageContent
      orientationVideoUrl
    }
  }
`

const Query = graphql`
  query OrientationQuery {
    learner {
      ...AppContainer_learner
      ...Orientation_learner
    }
  }
`

const SeenWelcomeMutation = graphql`
  mutation OrientationSeenWelcomeMutation {
    seenWelcome {
      id
      seenWelcome
    }
  }
`

type OrientationComponentProps = {
  learner: Orientation_learner$key
}

function OrientationComponent({ learner: key }: OrientationComponentProps) {
  const data = useFragment(Fragment, key)
  const [currentPage, setCurrentPage] = useState(1)
  const { aboutPageTitle, aboutPageContent } = data?.customer || {}
  const [updateSeenWelcome] = useMutation(SeenWelcomeMutation)
  const hasCustomContent = aboutPageContent && aboutPageTitle

  const firstTimeLearner = data?.assignments.totalCount == 0

  const navigate = useNavigate()

  const videoFinished = useRef(false)
  const videoRef = useRef<HTMLVideoElement>(null)
  const { interact } = useContext(InteractionContext)
  function videoInteract(n: string, other?: Record<string, unknown>) {
    interact(n, {
      ...(other || {}),
      videoFinished: videoFinished.current,
      videoTimestamp: videoRef?.current?.currentTime,
    })
  }

  const handleContinueConfirm = () => {
    videoInteract('continue_confirm')
    updateSeenWelcome({
      variables: {},
      onCompleted: () => {
        navigate({ to: '/lms' })
      },
    })
  }

  const videoUrl =
    data?.customer.orientationVideoUrl ||
    `https://client.flintls.com/2dot0/${
      firstTimeLearner ? 'OTJOrientation' : 'OTJOrientation_alt'
    }${data?.locale === 'fr-CA' ? '-FR' : ''}.mp4`

  function MenuItem({ page, title }: { page: number; title: string }) {
    return (
      <button
        className={`flex cursor-pointer items-center gap-2 ${
          page === currentPage ? 'opacity-100' : 'opacity-50'
        }`}
        onClick={() => setCurrentPage(page)}
      >
        <div
          className={`h-1.5 w-1.5 rounded-full ${
            page === currentPage ? 'bg-flintBlue' : 'bg-slate-300'
          } `}
        ></div>
        {title}
      </button>
    )
  }

  return (
    <>
      <div className="pb-24 pt-12">
        <div className="contain">
          <Card className="text-sm">
            <CardContent
              className={`${hasCustomContent && 'md:flex md:divide-x'}`}
            >
              {hasCustomContent ? (
                <div className="hidden flex-shrink-0 space-y-2 md:block md:pr-12">
                  <MenuItem page={1} title="What Are On-the-Job Activities?" />
                  <MenuItem page={2} title={aboutPageTitle} />
                </div>
              ) : null}

              <div
                className={`flex-grow space-y-6 ${
                  hasCustomContent && 'md:pl-12'
                }`}
              >
                <div className="flex justify-between">
                  <div className="space-y-3">
                    <CardTitle>
                      {currentPage === 1
                        ? 'What Are On-the-Job Activities?'
                        : aboutPageTitle}
                    </CardTitle>
                  </div>
                  {currentPage === 2 && (
                    <>
                      {data?.localizedLogoUrl ? (
                        <img
                          src={data.localizedLogoUrl}
                          alt={data.customer.name}
                          className="h-9"
                          translate="no"
                        />
                      ) : (
                        <p className="font-semibold" translate="no">
                          {data?.customer.name}
                        </p>
                      )}
                    </>
                  )}
                </div>

                {currentPage === 1 && (
                  <div>
                    {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                    <video
                      ref={videoRef}
                      controls
                      poster={
                        data?.locale === 'fr-CA'
                          ? '/video-thumbnail-FR.png'
                          : '/video-thumbnail.png'
                      }
                      controlsList="nodownload"
                      className="aspect-video w-full rounded-lg border border-slate-950/5"
                      onPlay={() => videoInteract('video_play')}
                      onEnded={() => {
                        videoFinished.current = true
                        videoInteract('video_finish')
                      }}
                      onPause={(event: SyntheticEvent<HTMLVideoElement>) => {
                        // `onPause` is also triggered when seeking or ending,
                        // but we only want to log manual pauses here.
                        if (
                          !event.currentTarget.seeking &&
                          !event.currentTarget.ended
                        ) {
                          videoInteract('video_pause')
                        }
                      }}
                      onSeeked={() => videoInteract('video_seek')}
                    >
                      <source src={videoUrl} type="video/mp4" />
                      Your browser does not support the video tag.
                    </video>
                  </div>
                )}

                {currentPage === 2 && aboutPageContent ? (
                  <Markdown>{aboutPageContent}</Markdown>
                ) : null}

                <div className="flex justify-end gap-3">
                  {currentPage === 2 && (
                    <Button
                      onClick={() => {
                        setCurrentPage(1)
                        videoInteract('tab_change', { tab: 1 })
                      }}
                    >
                      Previous
                    </Button>
                  )}
                  {aboutPageContent && currentPage == 1 ? (
                    <>
                      <Button
                        onClick={() => {
                          setCurrentPage(2)
                          videoInteract('tab_change', { tab: 2 })
                        }}
                      >
                        Next
                      </Button>
                    </>
                  ) : (
                    (!aboutPageContent ||
                      (aboutPageContent && currentPage === 2)) && (
                      <Button onClick={handleContinueConfirm}>
                        Continue
                        {firstTimeLearner ? ' to Survey' : null}
                      </Button>
                    )
                  )}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      </div>
    </>
  )
}

function Orientation() {
  const data = useLazyLoadQuery<OrientationQuery>(Query, {})
  if (!data.learner) {
    return <Navigate to="/lms" />
  }
  return (
    <AppContainer page="orientation" learner={data.learner}>
      <OrientationComponent learner={data.learner} />
    </AppContainer>
  )
}

export default Orientation

import {
  Environment,
  Network,
  RecordSource,
  Store,
  FetchFunction,
} from 'relay-runtime'
import { callGraphQL, getIdToken, getRole } from '@/common/auth'
import {
  RelayEnvironmentProvider as RelayEnvironmentProviderBroken,
  Environment as EnvironmentBroken,
} from 'react-relay'

const fetchFn: FetchFunction = async (request, variables) => {
  const idToken = await getIdToken()
  const role = getRole()

  const json = await callGraphQL(
    request.text || '',
    variables,
    idToken || '',
    role
  )

  if (json.errors) {
    if (!window.location.href.includes('/sso/') && import.meta.env.PROD) {
      window.location.href = '/lms/logout'
    }
  }

  return json
}

function createRelayEnvironment() {
  return new Environment({
    network: Network.create(fetchFn),
    store: new Store(new RecordSource()),
  })
}

export const RelayEnvironment = createRelayEnvironment()

// BS to fix this issue: https://github.com/facebook/relay/issues/4598
export const RelayEnvironmentProvider =
  RelayEnvironmentProviderBroken as unknown as (props: {
    children: React.ReactNode
    environment: EnvironmentBroken
  }) => React.ReactElement

import { useEffect, useRef } from 'react'
import { graphql, useFragment, useMutation } from 'react-relay'
import { useNavigate } from '@tanstack/react-router'

import { Button } from '@/components/Button'
import { Card, CardContent, CardHeader } from '@/components/Card'
import Markdown from '@/components/Markdown'

import { Template } from '@/components/lms/Surveys/Template'
import { QuestionCard } from '@/components/lms/Surveys/QuestionCard/QuestionCard'

import { MyLearnerSurveyFragment$key } from './__generated__/MyLearnerSurveyFragment.graphql'
import { useCompletedAnswers } from '@/components/lms/Surveys/completedAnswers'

const SurveyFragment = graphql`
  fragment MyLearnerSurveyFragment on Learner {
    id
    ...TemplateLearnerFragment
    ...QuestionCardLearnerFragment
    customer {
      id
    }
    pendingSurveyFromManager {
      id
      endDate
      survey {
        id
        instructions
        title
      }
      sections {
        id
        answers {
          id
          ...QuestionCardFragment
        }
      }
      ...completedAnswersFragment
    }
    surveyAwaitingResponseFromManager {
      id
    }
  }
`

const CreateResponseMutation = graphql`
  mutation MyLearnerSurveyCreateResponseMutation(
    $learner: ID!
    $surveyAssignment: ID!
  ) {
    createSurveyResponseFromManager(
      learner: $learner
      surveyAssignment: $surveyAssignment
    ) {
      id
      learnerDescribed {
        id
        ...MyLearnerSurveyFragment
      }
    }
  }
`

const SubmitResponseMutation = graphql`
  mutation MyLearnerSurveySubmitResponseMutation($response: ID!) {
    submitSurveyResponse(response: $response) {
      id
      learner {
        id
        ...MyLearnerSurveyFragment
      }
    }
  }
`

export type MyLearnerSurveyProps = {
  fragmentRef: MyLearnerSurveyFragment$key
}

export default function MyLearnerSurvey({ fragmentRef }: MyLearnerSurveyProps) {
  const navigate = useNavigate({ from: '/lms/my-learners/$myLearnerID' })
  const learner = useFragment(SurveyFragment, fragmentRef)
  const surveyAssignment = learner.surveyAwaitingResponseFromManager?.id
  const surveyResponse = learner.pendingSurveyFromManager
  const completedAnswers = useCompletedAnswers({ responseRef: surveyResponse })
  const [submit, submitting] = useMutation(SubmitResponseMutation)
  const [createResponse] = useMutation(CreateResponseMutation)
  const createdResponse = useRef(false)

  useEffect(() => {
    if (!createdResponse.current && !surveyResponse && surveyAssignment) {
      createdResponse.current = true
      createResponse({
        variables: { surveyAssignment, learner: learner.id },
      })
    }
  }, [learner, surveyResponse, surveyAssignment, createResponse])

  function handleOnSubmit() {
    if (surveyResponse) {
      submit({
        variables: { response: surveyResponse.id },
        onCompleted: () => {
          navigate({ to: '/lms/my-learners' })
        },
      })
    }
  }
  if (!surveyResponse) {
    return null
  }

  return (
    <div className="grid grid-cols-6 gap-6">
      <Card className="col-span-6">
        <CardHeader>
          <div className="space-y-3 text-center">
            <h1 className="text-2xl font-semibold text-flintBlue">
              <Template
                text={surveyResponse.survey.title}
                learnerRef={learner}
              />
            </h1>
            <Markdown>{surveyResponse.survey.instructions}</Markdown>
          </div>
        </CardHeader>
        <CardContent className="space-y-6 text-center">
          {surveyResponse.sections.map((section) => (
            <div key={section.id}>
              {section.answers.map((answer) => {
                return (
                  <QuestionCard
                    answerRef={answer}
                    learnerRef={learner}
                    customerId={learner.customer.id}
                    showPreviousAnswer={false}
                    expectationsAsHover={false}
                    key={answer.id}
                  />
                )
              })}
            </div>
          ))}
          <Button
            disabled={submitting || completedAnswers.length === 0}
            onClick={handleOnSubmit}
            {...(submitting ? { variant: 'loading' } : {})}
          >
            Submit Answers
          </Button>
        </CardContent>
      </Card>
    </div>
  )
}

import { getApp } from 'firebase/app'
import { getDownloadURL } from 'firebase/storage'

import { ref } from 'firebase/storage'

import { getStorage } from 'firebase/storage'

export const loadVideo = async (customerID: string, answerID: string) => {
  const storage = getStorage(getApp(), 'saurai.firebasestorage.app')
  const videoRef = ref(
    storage,
    `customers/${customerID}/answers/${answerID}.webm`
  )
  const url = await getDownloadURL(videoRef)
  const response = await fetch(url)
  const blob = await response.blob()
  return URL.createObjectURL(blob)
}

import { AnyRoute, createRoute } from '@tanstack/react-router'

import { z } from 'zod'

const justGfSearch = z.object({
  gf: z.string().optional(),
})

// Weirdly you have to keep getParentRoute generic because Tanstack uses the
//   type of the parent route as part of the type of the child route
function getReportRoutes<T extends AnyRoute>(getParentRoute: () => T) {
  const reportsRoute = createRoute({
    getParentRoute,
    path: '/reports',
    validateSearch: (search) => justGfSearch.parse(search),
  }).lazy(() => import('@/routes/reports/ReportsRoot').then((d) => d.Route))

  const activiesSearch = z.object({
    gf: z.string().optional(),
    sort: z
      .array(
        z.object({
          id: z.string(),
          desc: z.boolean(),
        })
      )
      .optional(),
  })

  const allActivitiesRoute = createRoute({
    getParentRoute,
    path: '/reports/all-activities',
    validateSearch: justGfSearch,
  }).lazy(() =>
    import('@/routes/reports/AllActivitiesRenderer').then((d) => d.Route)
  )
  const activitiesRoute = createRoute({
    getParentRoute,
    path: '/reports/activities',
    validateSearch: activiesSearch,
  }).lazy(() => import('@/routes/reports/Activities').then((d) => d.Route))
  const activityRoute = createRoute({
    getParentRoute,
    path: '/reports/activities/$activityID',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Activity').then((d) => d.Route))

  const contestsRoute = createRoute({
    getParentRoute,
    path: '/reports/contests',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Contests').then((d) => d.Route))
  const contestRoute = createRoute({
    getParentRoute,
    path: '/reports/contests/$contestID',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Contest').then((d) => d.Route))

  const feedbackRoute = createRoute({
    getParentRoute,
    path: '/reports/feedback',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Feedback').then((d) => d.Route))

  const experimentsRoute = createRoute({
    getParentRoute,
    path: '/reports/experiments',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Experiments').then((d) => d.Route))
  const experimentRoute = createRoute({
    getParentRoute,
    path: '/reports/experiments/$experimentID',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Experiment').then((d) => d.Route))
  const treatmentRoute = createRoute({
    getParentRoute,
    path: '/reports/experiments/$experimentID/$treatmentID',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Treatment').then((d) => d.Route))

  const initiativesRoute = createRoute({
    getParentRoute,
    path: '/reports/initiatives',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Initiatives').then((d) => d.Route))
  const initiativeRoute = createRoute({
    getParentRoute,
    path: '/reports/initiatives/$initiativeID',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Initiative').then((d) => d.Route))

  const learnerSearch = z.object({
    gf: z.string().optional(),
    completedAssignment: z.boolean().optional(),
    completedPersonalityTest: z.boolean().optional(),
    dueBefore: z.number().optional(),
    notDueBefore: z.number().optional(),
    hasAvailableAssignments: z.boolean().optional(),
    hasCurrentAssignment: z.boolean().optional(),
    hasPendingBlockingSurvey: z.boolean().optional(),
    treatment: z.string().optional(),
    highlight: z.string().optional(),
  })

  const learnersRoute = createRoute({
    getParentRoute,
    path: '/reports/learners',
    validateSearch: learnerSearch,
  }).lazy(() => import('@/routes/reports/Learners').then((d) => d.Route))

  const learnerRoute = createRoute({
    getParentRoute,
    path: '/reports/learners/$learnerID',
    validateSearch: learnerSearch,
  }).lazy(() => import('@/routes/reports/Learner').then((d) => d.Route))

  const profilesRoute = createRoute({
    getParentRoute,
    path: '/reports/profiles',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Profiles').then((d) => d.Route))

  const surveysRoute = createRoute({
    getParentRoute,
    path: '/reports/surveys',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Surveys.tsx').then((d) => d.Route))

  const surveyRoute = createRoute({
    getParentRoute,
    path: '/reports/surveys/$surveyID',
    validateSearch: justGfSearch,
  }).lazy(() => import('@/routes/reports/Survey/Survey').then((d) => d.Route))

  return [
    feedbackRoute,
    allActivitiesRoute,
    activitiesRoute,
    activityRoute,
    contestsRoute,
    contestRoute,
    initiativesRoute,
    initiativeRoute,
    learnersRoute,
    learnerRoute,
    profilesRoute,
    surveysRoute,
    surveyRoute,
    reportsRoute,
    experimentsRoute,
    experimentRoute,
    treatmentRoute,
  ]
}

export default getReportRoutes

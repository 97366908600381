import { Fragment as ReactFragment } from 'react'
import { ActivityQuery } from './__generated__/ActivityQuery.graphql'
import { graphql, useFragment, useLazyLoadQuery } from 'react-relay'
import SelectActivity from './SelectActivity'
import { Navigate, useNavigate, useSearch } from '@tanstack/react-router'
import { useEffect, useState, useRef, useCallback } from 'react'
import CurrentActivity from './CurrentActivity'
import ActivityCheckCompletion from './CheckCompletion'
import { Activity_learner$key } from './__generated__/Activity_learner.graphql'
import { useEndAssignment } from './activityHooks'
import { GeneralSurveyComponent } from '../GeneralSurvey'
import { CompletedDialog } from '@/components/lms/Initiatives/CompletedDialog'
import { FirstTimeDialog } from '@/components/lms/Initiatives/FirstTimeDialog'
import { EndDialog } from '@/components/lms/Initiatives/EndDialog'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

// short delay for dev makes testing easier
const DELAY = import.meta.env.DEV ? 1000 * 20 : 1000 * 60 * 60

const Fragment = graphql`
  fragment Activity_learner on Learner {
    currentAssignment {
      id
      choiceDate
      ...CompleteActivity_assignment
    }
    availableAssignments {
      id
    }
    receivingActivities
    pendingSurvey {
      id
    }
    surveyAwaitingResponse {
      id
    }
    ...AppContainer_learner
    ...GeneralSurveyFragment
    ...CurrentActivity_learner
    ...SelectActivity_learner
    ...CheckCompletion_learner
    ...CompletedDialogLearnerFragment
    ...FirstTimeDialogLearnerFragment
    ...EndDialogLearnerFragment
    currentInitiative: initiatives(membershipFilter: { atDate: 0 }) {
      edges {
        cursor
        ...CompletedDialogEdgeFragment
        ...FirstTimeDialogEdgeFragment
        initiative: node {
          id
          showChoices
        }
      }
    }
    pastInitiatives: initiatives(membershipFilter: { beforeDate: 0 }) {
      edges {
        ...EndDialogEdgeFragment
        cursor
        startDate
        seenEnd: seenPopup(popupType: InitiativePopupEnd)
        initiative: node {
          id
          showChoices
        }
      }
    }
  }
`

function ActivityComponent({
  learner: key,
  complete,
}: {
  learner: Activity_learner$key
  complete: boolean
}) {
  const learner = useFragment(Fragment, key)
  const [endAssignment] = useEndAssignment()
  const completedRef = useRef(false)

  const [keepGoing, setKeepGoing] = useState(false)
  const [congratulations, setCongratulations] = useState<
    typeof learner.currentAssignment | null
  >(null)

  const handleComplete = useCallback(() => {
    if (learner.currentAssignment) {
      setCongratulations(learner.currentAssignment)
      endAssignment(learner.currentAssignment.id, true, () => {})
    }
  }, [learner.currentAssignment, endAssignment])

  useEffect(() => {
    if (complete && !completedRef.current) {
      completedRef.current = true
      handleComplete()
    }
  }, [complete, handleComplete])

  if (congratulations) {
    return (
      <CurrentActivity
        learner={learner}
        congratulations={congratulations}
        onContinue={() => setCongratulations(null)}
      />
    )
  }
  if (learner.pendingSurvey || learner.surveyAwaitingResponse) {
    return <GeneralSurveyComponent redirect={false} learner={learner} />
  }
  if (learner.availableAssignments.length) {
    return <SelectActivity learner={learner} />
  } else if (learner.currentAssignment) {
    const diffTime =
      new Date().getTime() - (learner.currentAssignment.choiceDate || 0)
    if (diffTime < DELAY || keepGoing) {
      return <CurrentActivity learner={learner} onComplete={handleComplete} />
    } else {
      return (
        <ActivityCheckCompletion
          learner={learner}
          onComplete={handleComplete}
          onContinue={() => setKeepGoing(true)}
        />
      )
    }
  } else if (learner.receivingActivities) {
    return <CurrentActivity learner={learner} />
  } else {
    return <Navigate to="/lms" />
  }
}

export default function Activity() {
  const data = useLazyLoadQuery<ActivityQuery>(
    graphql`
      query ActivityQuery {
        learner {
          ...Activity_learner
        }
      }
    `,
    {}
  )

  const queryParams = useSearch({ from: '/lms/activity' })

  const navigate = useNavigate()
  useEffect(() => {
    navigate({
      to: '/lms/activity',
      replace: true,
    })
  }, [navigate])

  const learner = useFragment<Activity_learner$key>(Fragment, data.learner)

  const currentInitiatives =
    learner?.currentInitiative.edges.filter(
      (initiative) => !initiative.initiative.showChoices
    ) || []
  const pastInitiatives =
    learner?.pastInitiatives.edges
      .filter((initiative) => !initiative.initiative.showChoices)
      .sort((a, b) => b.startDate - a.startDate) || []

  const mostRecentPastInitiative =
    pastInitiatives.length > 0 ? pastInitiatives[0] : null

  const [showEndDialog, setShowEndDialog] = useState(
    mostRecentPastInitiative && !mostRecentPastInitiative.seenEnd
  )

  if (!data.learner || !learner) {
    return <Navigate to="/lms" />
  }

  return (
    <AppContainer page="activity" learner={learner}>
      {showEndDialog && mostRecentPastInitiative ? (
        <EndDialog
          edgeRef={mostRecentPastInitiative}
          learnerRef={learner}
          onClose={() => setShowEndDialog(false)}
        />
      ) : (
        currentInitiatives.map((initiative) => (
          <ReactFragment key={initiative.cursor}>
            <CompletedDialog edgeRef={initiative} learnerRef={learner} />
            <FirstTimeDialog edgeRef={initiative} learnerRef={learner} />
          </ReactFragment>
        ))
      )}
      <ActivityComponent
        learner={data.learner}
        complete={!!queryParams.complete}
      />
    </AppContainer>
  )
}

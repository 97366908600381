import { graphql, useMutation } from 'react-relay'
import { toast } from 'sonner'
import { activityHooksPracticeMutation } from './__generated__/activityHooksPracticeMutation.graphql'
import { activityHooksAssignActivityMutation } from './__generated__/activityHooksAssignActivityMutation.graphql'
import { activityHooksEndAssignmentMutation } from './__generated__/activityHooksEndAssignmentMutation.graphql'
import { activityHooksGenerateAssignmentsMutation } from './__generated__/activityHooksGenerateAssignmentsMutation.graphql'

const EndAssignmentMutation = graphql`
  mutation activityHooksEndAssignmentMutation(
    $assignmentID: ID!
    $complete: Boolean!
  ) {
    endAssignment(
      assignment: $assignmentID
      complete: $complete
      feedback: "{}"
    ) {
      learner {
        ...Activity_learner
      }
    }
  }
`

const PracticeMutation = graphql`
  mutation activityHooksPracticeMutation($practiceID: ID) {
    setCurrentPractice(practice: $practiceID) {
      learner {
        ...Activity_learner
        practice {
          id
          nextActivity {
            id
          }
        }
      }
    }
  }
`

const AssignActivityMutation = graphql`
  mutation activityHooksAssignActivityMutation($activityID: ID!) {
    assignActivity(activity: $activityID) {
      id
      learner {
        id
        ...Activity_learner
      }
    }
  }
`

const GenerateAssignmentsMutation = graphql`
  mutation activityHooksGenerateAssignmentsMutation {
    generateAssignments {
      id
      learner {
        id
        ...Activity_learner
      }
    }
  }
`

const onError = (what: string) => () => {
  toast.error(`Error ${what}`)
}

export function useSetPractice(currentAssignment?: string) {
  const [commit, isCommitting] =
    useMutation<activityHooksPracticeMutation>(PracticeMutation)

  const [endAssignment, isEndingAssignment] = useEndAssignment()

  const [setActivity, isAssigningActivity] = useAssignActivity()

  const setPractice = (practice?: string, onCompleted?: () => void) => {
    commit({
      variables: { practiceID: practice },
      onCompleted: (result) => {
        const nextActivity =
          result?.setCurrentPractice?.learner?.practice?.nextActivity?.id
        if (nextActivity) {
          setActivity(nextActivity, onCompleted)
        } else {
          onCompleted?.()
        }
      },
      onError: onError('setting practice'),
    })
  }

  const result = (practice?: string, onCompleted?: () => void) => {
    if (currentAssignment) {
      endAssignment(currentAssignment, false, () => {
        setPractice(practice, onCompleted)
      })
    } else {
      setPractice(practice, onCompleted)
    }
  }

  return [
    result,
    isCommitting || isAssigningActivity || isEndingAssignment,
  ] as const
}

export function useAssignActivity(currentAssignment?: string) {
  const [commit, isCommitting] =
    useMutation<activityHooksAssignActivityMutation>(AssignActivityMutation)
  const [endAssignment, isEndingAssignment] = useEndAssignment()

  return [
    (activity: string, onCompleted?: () => void) => {
      if (currentAssignment) {
        endAssignment(currentAssignment, false, () => {
          commit({
            variables: { activityID: activity },
            onCompleted,
            onError: onError('assigning activity'),
          })
        })
      } else {
        commit({
          variables: { activityID: activity },
          onCompleted,
          onError: onError('assigning activity'),
        })
      }
    },
    isCommitting || isEndingAssignment,
  ] as const
}

// This only gets called if the current initiatve has no practices
export function useGenerateAssignments() {
  const [commit, isCommitting] =
    useMutation<activityHooksGenerateAssignmentsMutation>(
      GenerateAssignmentsMutation
    )
  return [
    (onCompleted?: () => void) =>
      commit({
        variables: {},
        onCompleted,
        onError: onError('generating assignments'),
      }),
    isCommitting,
  ] as const
}

export function useEndAssignment() {
  const [commit, isCommitting] =
    useMutation<activityHooksEndAssignmentMutation>(EndAssignmentMutation)
  return [
    (assignment: string, complete: boolean, onCompleted?: () => void) => {
      commit({
        variables: { assignmentID: assignment, complete },
        onCompleted,
        optimisticUpdater: (store) => {
          // NOTE: without this updater, the UI takes a long time to react to activity changes.  This improves that dramatically.
          const learner = store.get(assignment)?.getLinkedRecord('learner')
          if (learner) {
            learner.setValue(null, 'currentAssignment')
          }
        },
        onError: onError('completing assignment'),
      })
    },
    isCommitting,
  ] as const
}

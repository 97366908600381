/**
 * @generated SignedSource<<0ab7a008c8f16f750d1197af278d0489>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FeaturedFeedbackFragment$data = ReadonlyArray<{
  readonly editedText: string | null;
  readonly editedVideo: boolean;
  readonly id: string;
  readonly learnerAnswering: {
    readonly customer: {
      readonly id: string;
    };
    readonly fullName: string | null;
    readonly id: string;
    readonly jobTitle: string | null;
  };
  readonly text: string | null;
  readonly videoUploaded: boolean;
  readonly " $fragmentType": "FeaturedFeedbackFragment";
}>;
export type FeaturedFeedbackFragment$key = ReadonlyArray<{
  readonly " $data"?: FeaturedFeedbackFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FeaturedFeedbackFragment">;
}>;

const node: ReaderFragment = (function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "FeaturedFeedbackFragment",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "Learner",
      "kind": "LinkedField",
      "name": "learnerAnswering",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "fullName",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Customer",
          "kind": "LinkedField",
          "name": "customer",
          "plural": false,
          "selections": [
            (v0/*: any*/)
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "jobTitle",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editedText",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "videoUploaded",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "editedVideo",
      "storageKey": null
    }
  ],
  "type": "SurveyAnswer",
  "abstractKey": null
};
})();

(node as any).hash = "f3f623d8854669cfab5a02f89c62bf2b";

export default node;

import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from '@/components/Tooltip'

function PreviousChoiceTooltip({
  active,
  children,
}: {
  active: boolean
  children: React.ReactNode
}) {
  if (active) {
    return (
      <TooltipProvider delayDuration={150}>
        <Tooltip>
          <TooltipTrigger asChild>{children}</TooltipTrigger>
          <TooltipContent className="-top-1.5">
            Previous selection
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    )
  } else {
    return <>{children}</>
  }
}

type RadioQuestionProps = {
  question: {
    choiceLabels: readonly string[] | null
    choiceCount: number
  }
  previousAnswer: number | null
  answer: number | null
  setAnswer: (choice: number) => void
}

export function RadioQuestion({
  answer,
  question: { choiceCount, choiceLabels },
  previousAnswer,
  setAnswer,
}: RadioQuestionProps) {
  const labels =
    choiceLabels ??
    [...Array(choiceCount)].map((_, n) =>
      (choiceCount == 11 ? n : n + 1).toString()
    )

  // Check if any label is longer than 3 characters
  const hasLongLabels = labels.some((label) => label.length > 3)

  return (
    <div
      className={
        'group mx-auto ' +
        (hasLongLabels
          ? 'flex flex-col space-y-2'
          : 'flex w-full items-center justify-between sm:justify-center sm:gap-6')
      }
    >
      {[...Array(choiceCount)].map((_, n) => (
        <PreviousChoiceTooltip key={n} active={previousAnswer === n}>
          <label
            className={
              'flex cursor-pointer items-center ' +
              (hasLongLabels
                ? 'w-full rounded-md p-2 text-sm font-semibold '
                : 'h-11 w-11 justify-center rounded-md text-sm font-semibold ') +
              (answer === n
                ? 'bg-flintBlue text-white'
                : 'bg-slate-200 opacity-50 hover:opacity-100' +
                  (previousAnswer === n ? ' ring-2 ring-flintBlue' : ''))
            }
          >
            <input
              type="radio"
              value={n.toString()}
              checked={answer === n}
              onChange={() => setAnswer(n)}
              className="sr-only"
            />
            <span className={hasLongLabels ? 'ml-3' : ''}>{labels[n]}</span>
          </label>
        </PreviousChoiceTooltip>
      ))}
    </div>
  )
}

import { initializeApp } from 'firebase/app'
import { getAuth, signInWithCustomToken } from 'firebase/auth'
import { router } from '@/routes'
import * as Sentry from '@sentry/react'

const firebaseConfig = {
  apiKey: 'AIzaSyAQalv6lXnmw_6BXQG56QS0WWKV3HgGg5E',
  authDomain: 'app.flintls.com',
  projectId: 'saurai',
  storageBucket: 'saurai.appspot.com',
  messagingSenderId: '344751390141',
  appId: '1:344751390141:web:f16c0e6e78d5b33301a6d4',
  measurementId: 'G-1E55Z28BK8',
}

const app = initializeApp(firebaseConfig)

export const callGraphQL = async (
  request: string,
  variables: Record<string, unknown>,
  authBearer: string,
  role: string
) => {
  const body = {
    query: request,
    variables,
  }

  const tic = import.meta.env.DEV ? new Date().getTime() : null

  const resp = await fetch(import.meta.env.VITE_GRAPHQL_ENDPOINT, {
    method: 'POST',
    headers: {
      Accept:
        'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8',
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + authBearer,
      'X-Flint-Role': role,
    },
    body: JSON.stringify(body),
  })

  const json = await resp.json()

  if (import.meta.env.DEV) {
    const toc = new Date().getTime()
    // eslint-disable-next-line no-console
    console.log(body, json, toc - (tic || 0))
  }

  if (json.errors) {
    Sentry.captureException(new Error('error from graphql'), {
      extra: {
        graphql_errors: JSON.stringify(json.errors),
        graphql_response: json,
        graphql_request: body,
      },
    })
  }

  return json
}

const TradeInMutation = `
  mutation TradeInMutation ($ticket: ID) {
    getFirebaseCustomToken(ticket: $ticket)
  }
`

export async function getTicketFromCurrentLearner(): Promise<string | null> {
  const currentID = await getIdToken()
  if (!currentID) {
    return null
  }
  const {
    data: {
      learner: {
        lmsUserID,
        customer: { id: customerID },
      },
    },
  } = await callGraphQL(
    `
      query LoginQuery {
        learner {
          lmsUserID
          customer {
            id
          }
        }
      }
    `,
    {},
    currentID,
    'lms'
  )
  if (!lmsUserID || !customerID) {
    return null
  }

  const {
    data: { getAccessTicket },
  } = await callGraphQL(
    `
      mutation LoginGetAccessTicketMutation(
      $lmsUserID: String!
      $customerID: String!
    ) {
      getAccessTicket(lmsUserID: $lmsUserID, customerToken: $customerID)
    }
    `,
    {
      lmsUserID,
      customerID,
    },
    currentID,
    'lms'
  )

  return getAccessTicket
}

export async function tradeInTicket(ticket: string) {
  const currentID = await getIdToken()
  const { data } = await callGraphQL(
    TradeInMutation,
    { ticket },
    currentID || '',
    'reports'
  )
  return signInWithCustomToken(auth, data.getFirebaseCustomToken)
}

// TODO: Remove once people stop relying on these cookies
export async function tradeOldLearnerId(learnerId: string) {
  const { data } = await callGraphQL(TradeInMutation, {}, learnerId, '')
  return signInWithCustomToken(auth, data.getFirebaseCustomToken)
}

export const auth = getAuth(app)

export async function getCurrentUser() {
  await auth.authStateReady()
  return auth.currentUser
}

export function getRole() {
  const pathname = router.state.location.pathname
  return pathname.startsWith('/lms') || pathname.startsWith('/sso/')
    ? 'lms'
    : pathname.startsWith('/admin') || pathname.startsWith('/library')
      ? 'admin'
      : pathname.startsWith('/reports') &&
          !pathname.startsWith('/reports/login')
        ? 'reports'
        : 'unknown'
}

export async function getIdToken() {
  const user = await getCurrentUser()
  return await user?.getIdToken()
}

import { useCallback, useEffect, useState } from 'react'
import { Card, CardContent } from '@/components/Card'
import { useGoalProgress } from '@/components/lms/Initiatives/goal-progress-hook'
import { InitiativeStatusEdgeFragment$key } from './__generated__/InitiativeStatusEdgeFragment.graphql'
import { graphql, useFragment } from 'react-relay'
import { Button } from '@/components/Button'
import { Replace, Trophy, Star } from 'lucide-react'
import { InitiativeStatusLearnerFragment$key } from './__generated__/InitiativeStatusLearnerFragment.graphql'
import { useSetPractice } from './activityHooks'
import { Link } from '@tanstack/react-router'
import PracticeRating from '@/components/lms/PracticeRating'
import { useInteraction } from '@/components/Interaction'

const LearnerFragment = graphql`
  fragment InitiativeStatusLearnerFragment on Learner {
    id
    ...goalProgressHookLearnerFragment
    currentAssignment {
      id
    }
    practice {
      id
      difficulty
      practice {
        id
        name
      }
      nextPractice {
        id
      }
      easierActivity {
        id
      }
      harderActivity {
        id
      }
      score
      managerScore
    }
  }
`

const EdgeFragment = graphql`
  fragment InitiativeStatusEdgeFragment on LearnerInitiativeEdge {
    node {
      id
      name
    }
    ...goalProgressHookEdgeFragment
  }
`

function TryNextPracticeButton({
  nextPracticeId,
  currentAssignmentId,
}: {
  nextPracticeId?: string
  currentAssignmentId?: string
}) {
  const interact = useInteraction()
  const [setPractice] = useSetPractice(currentAssignmentId)
  if (!nextPracticeId) return null

  return (
    <Button
      variant="outline"
      size="sm"
      className="flex h-auto w-48 items-center px-3 py-2"
      onClick={() => {
        interact('tryNextPractice')
        setPractice(nextPracticeId)
      }}
    >
      <Replace className="h-4 w-4 text-flintOrange" />
      <span className="ml-2">Try Something Else</span>
    </Button>
  )
}

interface InitiativeStatusProps {
  learnerRef: InitiativeStatusLearnerFragment$key
  initiativeRef: InitiativeStatusEdgeFragment$key
  initiativeAnimated: boolean
  practiceAnimated: boolean
  showNextPractice: boolean
}

export function InitiativeStatus({
  learnerRef,
  initiativeRef,
  initiativeAnimated,
  practiceAnimated,
  showNextPractice,
}: InitiativeStatusProps) {
  const initiative = useFragment(EdgeFragment, initiativeRef)
  const learner = useFragment(LearnerFragment, learnerRef)
  const { totalSteps, stepsCompleted } = useGoalProgress(learner, initiative)

  const calculateProgress = useCallback(
    (steps: number) => {
      return (steps / (totalSteps || 1)) * 100
    },
    [totalSteps]
  )

  const [barWidth, setBarWidth] = useState(
    calculateProgress(initiativeAnimated ? stepsCompleted - 1 : stepsCompleted)
  )

  useEffect(() => {
    if (initiativeAnimated) {
      setTimeout(() => {
        setBarWidth(calculateProgress(stepsCompleted))
      }, 200)
    }
  }, [initiativeAnimated, calculateProgress, stepsCompleted])

  return (
    <div className="flex flex-col gap-4 sm:flex-row">
      <Card
        animated={initiativeAnimated}
        className="min-w-full sm:w-1/2 sm:min-w-0"
      >
        <CardContent className="p-4 text-sm">
          {/* Initiative Progress Section */}
          <Link to="/lms/initiatives/current">
            <div className="space-y-3">
              <div className="flex flex-col gap-2">
                <div>
                  <h3 className="text-xs text-slate-400">Initiative</h3>
                  <h1 className="text-base font-semibold text-flintBlue">
                    {initiative?.node?.name}
                  </h1>
                </div>
                <div className="flex items-center justify-end gap-2">
                  <span className="font-medium">
                    {stepsCompleted} / {totalSteps} activities completed
                  </span>
                  {stepsCompleted > totalSteps && (
                    <Trophy className="h-5 w-5 text-flintOrange" />
                  )}
                </div>
              </div>

              <div className="relative h-3 w-full overflow-hidden rounded-full bg-slate-200">
                <div
                  className="absolute h-full bg-gradient-to-r from-[#7192b6] to-flintBlue"
                  style={{
                    width: `${Math.min(barWidth, 100)}%`,
                    transitionDuration: '1500ms',
                    transitionDelay: '1s',
                  }}
                />
                {stepsCompleted > totalSteps && (
                  <div
                    className="absolute h-full bg-flintOrange"
                    style={{
                      width: `${Math.min(((stepsCompleted - totalSteps) / stepsCompleted) * 100, 100)}%`,
                      right: '0px',
                      transitionDuration: '1500ms',
                      transitionDelay: '1s',
                    }}
                  />
                )}
              </div>

              {stepsCompleted > totalSteps && (
                <div className="flex items-center gap-2">
                  <Star className="h-4 w-4 shrink-0 text-flintOrange" />
                  <p className="text-xs leading-4 text-slate-600">
                    Amazing work! You&apos;ve completed{' '}
                    {stepsCompleted - totalSteps} bonus activities.
                  </p>
                </div>
              )}
            </div>
          </Link>
        </CardContent>
      </Card>

      {learner.practice?.practice?.name ? (
        <Card
          animated={practiceAnimated}
          className="min-w-full sm:w-1/2 sm:min-w-0"
        >
          <CardContent className="p-4 text-sm">
            {/* Practice Section */}
            <div className="space-y-4">
              <div className="flex flex-col gap-4 sm:flex-row sm:items-start sm:justify-between">
                <Link to="/lms/initiatives/current" className="space-y-1.5">
                  <h3 className="text-xs text-slate-400">Current Practice</h3>
                  <h1 className="text-base font-semibold text-flintBlue">
                    {learner.practice?.practice?.name}
                  </h1>
                </Link>

                <div className="hidden sm:block">
                  {showNextPractice ? (
                    <TryNextPracticeButton
                      nextPracticeId={learner.practice?.nextPractice?.id}
                      currentAssignmentId={learner.currentAssignment?.id}
                    />
                  ) : null}
                </div>
              </div>

              <Link to="/lms/initiatives/current" className="block py-2">
                <PracticeRating
                  self={learner.practice?.score}
                  manager={learner.practice?.managerScore}
                />
              </Link>

              <div className="pt-2 sm:hidden">
                {showNextPractice ? (
                  <TryNextPracticeButton
                    nextPracticeId={learner.practice?.nextPractice?.id}
                    currentAssignmentId={learner.currentAssignment?.id}
                  />
                ) : null}
              </div>
            </div>
          </CardContent>
        </Card>
      ) : null}
    </div>
  )
}

/* eslint-disable jsx-a11y/media-has-caption */
import { Button } from '../Button'
import { graphql, useFragment } from 'react-relay'
import { FeaturedFeedbackFragment$key } from './__generated__/FeaturedFeedbackFragment.graphql'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogDescription,
  DialogTrigger,
  DialogHeader,
} from '../Dialog'
import { loadVideo } from '@/common/surveyVideo'
import {
  Sparkles,
  ChevronLeft,
  ChevronRight,
  MessageSquare,
  Video,
} from 'lucide-react'
import { useEffect, useState } from 'react'

const Fragment = graphql`
  fragment FeaturedFeedbackFragment on SurveyAnswer @relay(plural: true) {
    id
    learnerAnswering {
      id
      fullName
      customer {
        id
      }
      jobTitle
    }
    text
    editedText
    videoUploaded
    editedVideo
  }
`

export type FeaturedFeedbackProps = {
  answersRef: FeaturedFeedbackFragment$key
}

export function FeaturedFeedback({ answersRef }: FeaturedFeedbackProps) {
  const answers = useFragment(Fragment, answersRef)
  const [video, setVideo] = useState<string | null>(null)
  const [currentIndex, setCurrentIndex] = useState(0)

  const handlePrevious = () =>
    setCurrentIndex((prev) => (prev > 0 ? prev - 1 : answers.length - 1))

  const handleNext = () =>
    setCurrentIndex((prev) => (prev < answers.length - 1 ? prev + 1 : 0))

  const currentAnswer =
    answers.length > currentIndex ? answers[currentIndex] : null

  useEffect(() => {
    if (!currentAnswer || !currentAnswer.videoUploaded) return
    loadVideo(
      currentAnswer.learnerAnswering.customer.id,
      currentAnswer.id
    ).then(setVideo)
  }, [currentAnswer])
  const feedbackText = currentAnswer?.editedText || currentAnswer?.text || ''

  if (!currentAnswer) return null

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm" className="bg-white">
          <Sparkles className="h-4 w-4" />
          Peer Reactions
        </Button>
      </DialogTrigger>
      <DialogContent size="lg" closeBtn>
        <DialogHeader>
          <DialogTitle>Peer Reactions</DialogTitle>
        </DialogHeader>
        <DialogDescription className="sr-only">
          Reactions from your peers.
        </DialogDescription>

        {answers.length > 0 ? (
          <>
            <div className="mb-4 flex items-center">
              <div
                className="mr-3 flex h-10 w-10 items-center 
                justify-center rounded-full bg-blue-100 text-blue-600"
              >
                {currentAnswer.videoUploaded ? (
                  <Video size={18} />
                ) : (
                  <MessageSquare size={18} />
                )}
              </div>
              <div>
                <p className="font-medium">
                  {currentAnswer.learnerAnswering.fullName || 'Anonymous'}
                </p>
                {currentAnswer.learnerAnswering.jobTitle ? (
                  <p className="text-sm text-gray-500">
                    {currentAnswer.learnerAnswering.jobTitle}
                  </p>
                ) : null}
              </div>
            </div>

            <div className="flex rounded-lg bg-gray-50 p-4">
              {currentAnswer.videoUploaded && video ? (
                <div className="mb-4 flex w-full items-center justify-center">
                  <video
                    src={video}
                    controls
                    className="max-h-[50vh] max-w-full rounded-md"
                  />
                </div>
              ) : (
                <p className="w-full text-left">{feedbackText}</p>
              )}
            </div>

            {/* Navigation controls */}
            {answers.length > 1 && (
              <div className="mt-4 flex items-center justify-between">
                <div className="text-sm text-gray-500">
                  {currentIndex + 1} of {answers.length}
                </div>
                <div className="flex space-x-2">
                  <Button onClick={handlePrevious} variant="ghost" size="sm">
                    <ChevronLeft size={20} />
                  </Button>
                  <Button onClick={handleNext} variant="ghost" size="sm">
                    <ChevronRight size={20} />
                  </Button>
                </div>
              </div>
            )}
          </>
        ) : (
          <p className="text-gray-500">No reactions available.</p>
        )}
      </DialogContent>
    </Dialog>
  )
}

import { Navigate, useNavigate } from '@tanstack/react-router'
import { motion } from 'framer-motion'
import { useEffect, useContext, useState } from 'react'
import {
  graphql,
  useFragment,
  useMutation,
  useLazyLoadQuery,
} from 'react-relay'

import { InteractionContext } from '@/components/Interaction'
import Markdown from '@/components/Markdown'
import ProgressBar from '@/components/lms/ProgressBar'
import { Button } from '@/components/Button'
import { Card, CardContent } from '@/components/Card'

import { HelpCircle } from 'lucide-react'
import { QuestionCard } from '@/components/lms/Surveys/QuestionCard/QuestionCard'

import type { GeneralSurveyCreateMutation } from './__generated__/GeneralSurveyCreateMutation.graphql'
import type { GeneralSurveyQuery } from './__generated__/GeneralSurveyQuery.graphql'
import type {
  GeneralSurveyFragment$data,
  GeneralSurveyFragment$key,
} from './__generated__/GeneralSurveyFragment.graphql'
import { toast } from 'sonner'
import { useCompletedAnswers } from '@/components/lms/Surveys/completedAnswers'
import { activityName, interpolateString } from '@/common/utils'
import AppContainer from '@/components/lms/AppContainer/AppContainer'

type Answer = NonNullable<
  GeneralSurveyFragment$data['pendingSurvey']
>['sections'][number]['answers'][number]

const SurveyFragment = graphql`
  fragment GeneralSurveyFragment on Learner {
    demoMode
    customer {
      id
    }
    pendingSurvey {
      id
      survey {
        id
        title
        instructions
      }
      sections {
        id
        title
        instructions
        answers {
          id
          ordinal
          question {
            id
            behavior {
              id
            }
          }
          ...QuestionCardFragment
          learnerDescribed {
            ...QuestionCardLearnerFragment
          }
        }
      }
      assignment {
        showPreviousChoices
      }
      activityAssignment {
        activity {
          name
        }
      }
      ...completedAnswersFragment
    }
    surveyAwaitingResponse {
      id
      survey {
        id
        title
        instructions
      }
    }
  }
`

const Query = graphql`
  query GeneralSurveyQuery {
    learner {
      ...AppContainer_learner
      ...GeneralSurveyFragment
    }
  }
`

const CreateResponseMutation = graphql`
  mutation GeneralSurveyCreateMutation($surveyAssignment: ID!) {
    createSurveyResponse(surveyAssignment: $surveyAssignment) {
      learner {
        id
        ...Activity_learner
        ...GeneralSurveyFragment
      }
    }
  }
`

// We request all the details of the pending survey in case there's another one
// waiting after this one is submitted. (In which case the router will remain
// on this page, and if any of the survey information were missing it would
// cause an error.)
const SubmitMutation = graphql`
  mutation GeneralSurveySubmitMutation($response: ID!) {
    submitSurveyResponse(response: $response) {
      id
      learner {
        id
        ...Activity_learner
        readyForAssignments
        ...GeneralSurveyFragment
        ...NewAchievementDialogFragment
      }
    }
  }
`

type GeneralSurveyComponentProps = {
  redirect?: boolean
  learner: GeneralSurveyFragment$key
}

export function GeneralSurveyComponent({
  redirect = true,
  learner,
}: GeneralSurveyComponentProps) {
  const surveyState = useFragment<GeneralSurveyFragment$key>(
    SurveyFragment,
    learner
  )
  const surveyResponse = surveyState?.pendingSurvey
  const surveyAssignment = surveyState?.surveyAwaitingResponse

  const navigate = useNavigate({ from: '/lms/general-survey' })
  const [submit, submitting] = useMutation(SubmitMutation)
  const [randomizing, setRandomizing] = useState(false)
  const [createResponse, creatingResponse] =
    useMutation<GeneralSurveyCreateMutation>(CreateResponseMutation)
  const { interact } = useContext(InteractionContext)
  const completedAnswers = useCompletedAnswers({ responseRef: surveyResponse })
  const [activeCompletedAnswers, setActiveCompletedAnswers] =
    useState(completedAnswers)

  useEffect(() => {
    if (!creatingResponse && !surveyResponse && surveyAssignment) {
      createResponse({
        variables: { surveyAssignment: surveyAssignment.id },
        onCompleted: (data) => {
          if (!data.createSurveyResponse && !surveyResponse) {
            if (redirect) {
              navigate({ to: '/lms' })
            }
          }
        },
      })
    }
  }, [
    createResponse,
    creatingResponse,
    navigate,
    surveyAssignment,
    surveyResponse,
    redirect,
  ])

  // FIXME: If we're getting the survey content from `surveyAssignment` it's
  // because we're waiting for `createResponse` to finish. When it does, the
  // page will flicker as it re-renders. It would be better to show a loading
  // screen while we wait.
  const survey = surveyResponse?.survey ?? surveyAssignment?.survey ?? null
  if (!survey) {
    return <Navigate to="/lms" replace={true} from="/lms/general-survey" />
  }

  const sections = surveyResponse?.sections.map((s) => s).reverse() ?? []
  const questionCount = sections.reduce((acc, s) => acc + s.answers.length, 0)

  const answerCount = completedAnswers.length
  const progress = Math.ceil((answerCount / questionCount) * 100)
  const showSubmit = surveyResponse && answerCount == questionCount

  const templ = (str: string) =>
    interpolateString(str, {
      activity: activityName({
        name: surveyResponse?.activityAssignment?.activity.name ?? '',
      }),
    })

  const behaviorsPresent = sections
    .flatMap((s) => s.answers)
    .some((a) => a.question.behavior)

  function showAnswer(answer: Answer) {
    return (
      answer.ordinal <
      (randomizing ? answerCount : activeCompletedAnswers.length) + 1
    )
  }

  const handleOnSubmit = async () => {
    await interact('submit')
    if (surveyResponse?.id == null) return
    submit({
      variables: { response: surveyResponse.id },
      onCompleted: () => {
        // Because we might reuse this component, we need to put the state back.
        // This is technically incorrect if the user partially filled out the next survey
        //  but that should be very rare and the behavior in that case would not be *awful*, just non-ideal
        // The problem is that you can't call useCompletedAnswers here to set the values properly
        // the right and proper fix would be to keep the state of "I put a couple of answers, but I'm not done with this question"
        // into the answer table on the database so that it correctly persists across pageloads/ multiple quesitons
        setActiveCompletedAnswers([])
        if (redirect) {
          navigate({ to: '/lms', search: { from: 'survey' } })
        }
      },
      onError(e) {
        toast.error('Unable to submit', {
          description: 'Please wait a moment and try again.',
          duration: 5000,
        })
        if (e instanceof Error) {
          interact('fail_submit', { message: e.message })
        } else {
          interact('fail_submit')
        }
      },
    })
  }

  return (
    <div className="mx-auto max-w-3xl space-y-6">
      <Card>
        <CardContent className="space-y-6">
          <div className="space-y-3 text-center">
            <h1 className="text-2xl font-semibold text-flintBlue">
              {templ(survey.title)}
            </h1>
            <Markdown>{templ(survey.instructions)}</Markdown>
            {behaviorsPresent ? (
              <p className="text-sm">
                <b>Tip:</b> When you see the{' '}
                <var>
                  <HelpCircle
                    className="relative -top-px mx-0.5 inline stroke-flintBlue"
                    size={18}
                    strokeWidth={2.25}
                  />
                </var>{' '}
                icon, click on it to learn more about what is being measured.
              </p>
            ) : null}
          </div>
          {questionCount ? <ProgressBar value={progress} /> : null}
          {surveyState.demoMode ? (
            <div className="mb-10 text-center">
              <Button
                variant="demo"
                onClick={() => setRandomizing(true)}
                className="mx-auto"
              >
                DEMO MODE: Randomize answers
              </Button>
            </div>
          ) : null}
          {showSubmit ? (
            <motion.div
              className="text-center"
              initial={{
                height: 0,
                opacity: 0,
                scale: 0.9,
              }}
              animate={{
                height: 'auto',
                opacity: 1,
                scale: 1,
                transition: {
                  height: {
                    duration: 0.75,
                    ease: [0.215, 0.61, 0.355, 1.0],
                  },
                  opacity: {
                    duration: 0.6,
                    delay: 0.2,
                  },
                  scale: {
                    duration: 0.6,
                    delay: 0.2,
                    ease: [0.215, 0.61, 0.355, 1.0],
                  },
                },
              }}
            >
              <Button
                onClick={handleOnSubmit}
                {...(submitting ? { variant: 'loading' } : {})}
              >
                Submit Answers
              </Button>
            </motion.div>
          ) : null}
        </CardContent>
      </Card>
      {sections.map((section) =>
        section.answers.length == 0 ||
        !showAnswer(section.answers[0]) ? null : (
          <motion.div
            initial={{
              height: 0,
              opacity: 0,
            }}
            animate={{
              height: 'auto',
              opacity: 1,
              transition: {
                height: {
                  duration: 0.75,
                  ease: [0.215, 0.61, 0.355, 1.0],
                },
                opacity: {
                  duration: 0.6,
                  delay: 0.2,
                },
              },
            }}
            key={section.id}
          >
            <Card>
              <CardContent className="space-y-6 text-center">
                <div className="space-y-2">
                  {section.title ? (
                    <h2 className="text-2xl font-semibold text-flintBlue">
                      {templ(section.title)}
                    </h2>
                  ) : null}
                  {section.instructions ? (
                    <Markdown>{templ(section.instructions)}</Markdown>
                  ) : null}
                </div>
                <div>
                  {section.answers
                    .filter((a) => showAnswer(a))
                    .map((answer) => {
                      return (
                        <QuestionCard
                          template={templ}
                          answerRef={answer}
                          customerId={surveyState.customer.id}
                          learnerRef={answer.learnerDescribed}
                          showPreviousAnswer={
                            surveyResponse?.assignment?.showPreviousChoices ??
                            true
                          }
                          showNextButton={
                            !activeCompletedAnswers.includes(answer.id) &&
                            completedAnswers.includes(answer.id) &&
                            completedAnswers.length !== questionCount &&
                            !randomizing
                          }
                          expectationsAsHover={answer.ordinal != answerCount}
                          onNextButtonClicked={() =>
                            setActiveCompletedAnswers((prev) =>
                              prev.includes(answer.id)
                                ? prev
                                : [...prev, answer.id]
                            )
                          }
                          randomize={
                            answer.ordinal == answerCount && randomizing
                          }
                          key={answer.id}
                        />
                      )
                    })
                    .reverse()}
                </div>
              </CardContent>
            </Card>
          </motion.div>
        )
      )}
    </div>
  )
}

function GeneralSurvey() {
  const { learner } = useLazyLoadQuery<GeneralSurveyQuery>(Query, {})
  if (!learner) return <Navigate to="/lms" replace={true} />
  return (
    <AppContainer page="general-survey" learner={learner}>
      <GeneralSurveyComponent redirect={true} learner={learner} />
    </AppContainer>
  )
}

export default GeneralSurvey

import { motion } from 'framer-motion'
import { useCallback, useEffect } from 'react'
import { RadioQuestion } from './RadioQuestion'
import { CheckboxQuestion } from './CheckboxQuestion'
import FreeformQuestion from './FreeformQuestion'
import { Prompt } from '../Prompt'
import { graphql, useFragment, useMutation } from 'react-relay'
import { QuestionCardFragment$key } from './__generated__/QuestionCardFragment.graphql'
import { QuestionCardLearnerFragment$key } from './__generated__/QuestionCardLearnerFragment.graphql'
import { toast } from 'sonner'
import {
  QuestionCardAnswerMutation,
  QuestionCardAnswerMutation$variables,
} from './__generated__/QuestionCardAnswerMutation.graphql'

const AnswerFragment = graphql`
  fragment QuestionCardFragment on SurveyAnswer {
    id
    ...PromptAnswerFragment
    ...FreeformQuestionFragment
    question {
      id
      choiceCount
      choiceLabels
      multipleResponses
      lastIsNoneOfTheAbove
    }
    choice
    choices
    text
    ordinal
    previousChoice
    videoUploaded
  }
`

const LearnerFragment = graphql`
  fragment QuestionCardLearnerFragment on Learner {
    ...PromptLearnerFragment
  }
`

const AnswerMutation = graphql`
  mutation QuestionCardAnswerMutation(
    $answer: ID!
    $choice: Int
    $text: String
    $choices: [Int!]
    $videoUploaded: Boolean
  ) {
    setSurveyAnswer(
      answer: $answer
      choice: $choice
      text: $text
      choices: $choices
      videoUploaded: $videoUploaded
    ) {
      id
      choice
      choices
      text
      videoUploaded
    }
  }
`

type QuestionCardProps = {
  answerRef: QuestionCardFragment$key
  learnerRef: QuestionCardLearnerFragment$key | null
  customerId: string
  template?: (str: string) => string
  showNextButton?: boolean
  onNextButtonClicked?: () => void
  showPreviousAnswer: boolean
  expectationsAsHover: boolean
  randomize?: boolean
}
export function QuestionCard({
  answerRef,
  learnerRef,
  customerId,
  template = (str) => str,
  showNextButton = false,
  onNextButtonClicked,
  showPreviousAnswer,
  randomize = false,
  expectationsAsHover,
}: QuestionCardProps) {
  const answer = useFragment(AnswerFragment, answerRef)
  const learner = useFragment(LearnerFragment, learnerRef) || null
  const [mutate, isMutating] =
    useMutation<QuestionCardAnswerMutation>(AnswerMutation)

  const setAnswer = useCallback(
    (variables: QuestionCardAnswerMutation$variables) => {
      return new Promise((resolve, reject) => {
        mutate({
          variables,
          onCompleted: resolve,
          onError(e) {
            toast.error('Unable to answer', {
              description: 'Please wait a moment and try again.',
              duration: 5000,
            })
            reject(e)
          },
        })
      })
    },
    [mutate]
  )

  useEffect(() => {
    if (randomize && !isMutating) {
      const choice = Math.floor(Math.random() * answer.question.choiceCount)
      setAnswer({
        answer: answer.id,
        ...(answer.question.choiceCount === 0
          ? { text: '(randomized)', choice: 0 }
          : answer.question.multipleResponses
            ? { choices: [choice] }
            : { choice }),
      })
    }
  }, [setAnswer, randomize, answer, isMutating])

  const choiceCount = answer.question.choiceCount
  const hackedNoExpectations = [
    '6b902163-398e-4f35-a328-3db5721c3966',
    '311df3a0-1971-4d70-924c-41b2b57ea462',
    '945e6986-b890-4c5b-af5d-58d5a62a9d88',
  ]

  return (
    <motion.div
      className="group"
      initial={
        answer.ordinal !== 0
          ? {
              height: 0,
              opacity: 0,
            }
          : {}
      }
      animate={
        answer.ordinal !== 0
          ? {
              height: 'auto',
              opacity: 1,
              transition: {
                height: {
                  duration: 0.75,
                  ease: [0.215, 0.61, 0.355, 1.0],
                },
                opacity: {
                  duration: 0.6,
                  delay: 0.2,
                },
              },
            }
          : {}
      }
    >
      <div className="space-y-4 py-4">
        <h3 className="font-semibold">
          <Prompt answerRef={answer} learnerRef={learner} template={template} />
        </h3>
        {choiceCount === 0 ? (
          <FreeformQuestion
            answerRef={answer}
            customerId={customerId}
            randomize={!isMutating && randomize}
            videoUploaded={answer.videoUploaded}
            markVideoUploaded={() => {
              setAnswer({
                answer: answer.id,
                choice: 0,
                videoUploaded: true,
                text: answer.text,
              })
              if (onNextButtonClicked) onNextButtonClicked()
            }}
            setAnswer={(text) => {
              // FIXME: It would seem more principled to store only the `text`
              // and not an incorrect `choice` value, especially since we now
              // have `multipleResponses` questions that don't store a single
              // `choice`. But it seems we've always stored a zero value for
              // the `choice` in the past, so I'm preserving that behavior
              // until it's proven safe to remove.
              setAnswer({
                answer: answer.id,
                choice: 0,
                text,
                videoUploaded: answer.videoUploaded,
              })
              if (onNextButtonClicked) onNextButtonClicked()
            }}
          />
        ) : answer.question.multipleResponses ? (
          <CheckboxQuestion
            answers={answer.choices}
            question={answer.question}
            showNextButton={showNextButton}
            onNextButtonClicked={() =>
              onNextButtonClicked ? onNextButtonClicked() : null
            }
            setAnswer={(choices) => setAnswer({ answer: answer.id, choices })}
          />
        ) : (
          <RadioQuestion
            answer={answer.choice}
            question={answer.question}
            previousAnswer={showPreviousAnswer ? answer.previousChoice : null}
            setAnswer={(choice) => {
              setAnswer({
                answer: answer.id,
                choice,
              })
              if (onNextButtonClicked) onNextButtonClicked()
            }}
          />
        )}
      </div>
      {choiceCount != 11 ||
      hackedNoExpectations.includes(answer.question.id) ? null : (
        <div
          className={
            'flex text-center text-sm font-semibold ' +
            (expectationsAsHover
              ? 'opacity-0 group-hover:opacity-70'
              : 'opacity-70')
          }
        >
          {learner ? (
            <>
              <span className="w-1/3">Below expectations</span>
              <span className="w-1/3">Meets expectations</span>
              <span className="w-1/3">Exceeds expectations</span>
            </>
          ) : (
            <>
              <span className="w-1/3">I see room for growth</span>
              <span className="w-1/3">I am as good as I need to be</span>
              <span className="w-1/3">I am very good at this</span>
            </>
          )}
        </div>
      )}
    </motion.div>
  )
}

/**
 * @generated SignedSource<<47a1e14c571eac9850e7904b2a18cc2a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type FreeformQuestionFragment$data = {
  readonly id: string;
  readonly text: string | null;
  readonly " $fragmentType": "FreeformQuestionFragment";
};
export type FreeformQuestionFragment$key = {
  readonly " $data"?: FreeformQuestionFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"FreeformQuestionFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "FreeformQuestionFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "text",
      "storageKey": null
    }
  ],
  "type": "SurveyAnswer",
  "abstractKey": null
};

(node as any).hash = "81a25833a352800c2925e5f290fe468b";

export default node;

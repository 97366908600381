import { Button } from '@/components/Button'
import { ArrowRight } from 'lucide-react'

type CheckboxQuestionProps = {
  question: {
    choiceLabels: readonly string[] | null
    choiceCount: number
    lastIsNoneOfTheAbove: boolean
  }
  answers: readonly number[] | null
  setAnswer: (choices: number[]) => void
  showNextButton: boolean
  onNextButtonClicked: () => void
}

export function CheckboxQuestion({
  answers,
  question: { choiceCount, choiceLabels, lastIsNoneOfTheAbove },
  setAnswer,
  showNextButton,
  onNextButtonClicked,
}: CheckboxQuestionProps) {
  const labels =
    choiceLabels ??
    [...Array(choiceCount)].map((_, n) =>
      (choiceCount == 11 ? n : n + 1).toString()
    )

  // Check if any label is longer than 3 characters
  const hasLongLabels = labels.some((label) => label.length > 3)

  // FIXME: don't let the user uncheck the last box
  const handleChange = (n: number) => {
    if (answers?.includes(n)) {
      setAnswer(answers.filter((a) => a !== n))
    } else {
      if (lastIsNoneOfTheAbove && n === choiceCount - 1) {
        // If selecting "None of the Above", deselect all others
        setAnswer([n])
      } else {
        // If selecting any other option, deselect "None of the Above"
        setAnswer(
          [...(answers || []), n].filter(
            (a) => a !== choiceCount - 1 || !lastIsNoneOfTheAbove
          )
        )
      }
    }
  }

  return (
    <div className="space-y-4">
      <div
        className={
          'group mx-auto ' +
          (hasLongLabels
            ? 'flex flex-col space-y-2'
            : 'flex w-full items-center justify-between sm:justify-center sm:gap-6')
        }
      >
        {[...Array(choiceCount)].map((_, n) => (
          <label
            key={n}
            className={
              'flex cursor-pointer items-center ' +
              (hasLongLabels
                ? 'w-full rounded-md p-2 text-sm font-semibold '
                : 'h-11 w-11 justify-center rounded-md text-sm font-semibold ') +
              (answers?.includes(n)
                ? 'bg-flintBlue text-white'
                : 'bg-slate-200 opacity-50 hover:opacity-100')
            }
          >
            <input
              type="checkbox"
              value={n.toString()}
              checked={answers?.includes(n)}
              onChange={() => handleChange(n)}
              className="sr-only"
            />
            <span className={hasLongLabels ? 'ml-3' : ''}>{labels[n]}</span>
          </label>
        ))}
      </div>
      {showNextButton ? (
        <div className="flex justify-end">
          <Button
            onClick={() => {
              onNextButtonClicked()
            }}
          >
            Next Question
            <ArrowRight size={16} />
          </Button>
        </div>
      ) : null}
    </div>
  )
}
